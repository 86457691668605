#scanner-container {
  position: relative;
  max-width: 100%; /* set maximum width to 100% */
  max-height: 50vh; /* allow height to adjust automatically */
  overflow: hidden; /* hide any overflow to prevent scrolling */
}

.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
